import React from 'react'
import Components from '../components/components.js'
import SbEditable from 'storyblok-react'
import config from '../../gatsby-config'
import getGlobalStyles from '../styles';
import { Helmet } from "react-helmet"
import StoryblokClient from 'storyblok-js-client';

let sbConfigs = config.plugins.filter((item) => {
  return item.resolve === 'gatsby-source-storyblok'
});
let sbConfig = sbConfigs.length > 0 ? sbConfigs[0] : {}

const loadStoryblokBridge = function(cb) {

  let script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = `//app.storyblok.com/f/storyblok-latest.js?t=${sbConfig.options.accessToken}`
  script.onload = cb
  document.getElementsByTagName('head')[0].appendChild(script)
}

const getParam = function(val) {
  var result = ''
  var tmp = []

  window.location.search
    .substr(1)
    .split('&')
    .forEach(function (item) {
      tmp = item.split('=')
      if (tmp[0] === val) {
        result = decodeURIComponent(tmp[1])
      }
    });

  return result;
}

class StoryblokEntry extends React.Component {
  constructor(props) {
    super(props)

    this.storyblokClient = new StoryblokClient({
      accessToken: sbConfig.options.accessToken
    });
    
    this.state = {story: null, globalNavi: {content: {}}, footer: {content: {}}, globalSettings: {content: {}}}
  }

  componentDidMount() {
    loadStoryblokBridge(() => { this.initStoryblokEvents() })
  }

  loadStory(payload) {
    window.storyblok.get({
      slug: getParam('path'),
      version: 'draft',
      resolve_relations: sbConfig.options.resolveRelations || []
    }, (data) => {
      this.setState({story: data.story})
      this.loadGlobalNavi(data.story.lang)
      this.loadGlobalSettings(data.story.lang)
      this.loadFooter(data.story.lang)
    })
  }

  loadGlobalNavi(lang) {
    const language = lang === 'default' ? '' : lang + '/'
    window.storyblok.get({
      slug: `${language}global-navi`,
      version: 'draft'
    }, (data) => {
      this.setState({globalNavi: data.story})
    })
  }

  loadGlobalSettings(lang) {
    const language = lang === 'default' ? '' : lang + '/'
    window.storyblok.get({
      slug: `${language}settings`,
      version: 'draft'
    }, (data) => {
      this.setState({globalSettings: data.story})
    })
  }

  loadFooter(lang) {
    const language = lang === 'default' ? '' : lang + '/'
    window.storyblok.get({
      slug: `${language}footer`,
      version: 'draft'
    }, (data) => {
      this.setState({footer: data.story})
    })
  }

  initStoryblokEvents() {
    this.loadStory({storyId: getParam('path')})

    let sb = window.storyblok

    sb.on(['change', 'published'], (payload) => {
      this.loadStory(payload)
    })

    sb.on('input', (payload) => {
      if (this.state.story && payload.story.id === this.state.story.id) {
        payload.story.content = sb.addComments(payload.story.content, payload.story.id)
        this.setState({story: payload.story})
      }
    })

    sb.pingEditor(() => {
      if (sb.inEditor) {
        sb.enterEditmode()
      }
    })
  }

  render() {
    if (this.state.story == null) {
      return (<div></div>)
    }

    let content = { ...this.state.story.content, storyblokClient: this.storyblokClient };
    let path = this.state.story.slug
    let footer = this.state.footer.content
    let settings = this.state.globalSettings.content
    const globalStyles = getGlobalStyles();
    const { favicon } = settings;

    return (
      <SbEditable content={content}>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="title"
            content={ settings['search_title'] }
          />
          <meta
            name="og:image"
            content={ settings['og_image'] }
          />
          <meta
            name="og:description"
            content={ settings['og_description'] }
          />
          <title>{ settings.title }</title>
          <link rel="icon" href={favicon ? favicon.filename : ''} type="image/png" sizes="16x16" />
        </Helmet>
        {React.createElement(Components(content.component), {key: content._uid, blok: content, path, footer})}
        { globalStyles }
      </div>
      </SbEditable>
    )
  }
}

export default StoryblokEntry
